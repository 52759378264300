import classNames from "classnames"
import * as React from "react"

import type { DateTime } from "@bounce/date"
import { isAfter, prepareMonthArray } from "@bounce/util"

import Day from "./Day"
import type { SelectedState } from "./types"

type DayGridProps = {
  currentMonth: DateTime
  minDate?: DateTime
  maxDate?: DateTime
  isDisabled?: (date: DateTime) => boolean
  isDaySelected?: (date: DateTime) => SelectedState
  onDateSelected?: (date: DateTime) => void
  weekStartsOn?: 0 | 1
  className?: string
}

const isDayUnavailable = (
  date: DateTime,
  currentMonth: DateTime,
  minDate?: DateTime,
  maxDate?: DateTime,
  isDisabled?: (d: DateTime) => boolean,
) => {
  if (!date.hasSame(currentMonth, "month")) {
    return true
  }
  if (isDisabled && isDisabled(date)) {
    return true
  }
  if (maxDate && isAfter(maxDate, date)) {
    return true
  }
  if (minDate && date.hasSame(minDate, "day")) {
    return false
  }
  if (minDate && date < minDate) {
    return true
  }
  return false
}

const DayGrid = ({
  currentMonth,
  weekStartsOn = 0,
  onDateSelected,
  isDisabled,
  isDaySelected,
  minDate,
  maxDate,
  className,
}: DayGridProps) => {
  const days = prepareMonthArray(currentMonth, {
    fillWeeks: true,
    weekStartsOn,
  })

  return (
    <div className={classNames("calendar__day-grid", className)}>
      {days.map((d, i) => (
        <Day
          onDaySelected={onDateSelected}
          key={i}
          date={d}
          disabled={isDisabled && isDisabled(d)}
          selected={isDaySelected && isDaySelected(d)}
          unavailable={isDayUnavailable(d, currentMonth, minDate, maxDate, isDisabled)}
        />
      ))}
    </div>
  )
}

export default DayGrid
